new Swiper('.partners-swiper', {
  navigation: {
    prevEl: '.partners-swiper .partners-swiper-btn-prev',
    nextEl: '.partners-swiper .partners-swiper-btn-next',
  },
  pagination: {
    el: '.partners-swiper .partners-swiper-pagination',
    clickable: true,
  },
  keyboard: {
    enabled: true,
    onlyInViewport: true,
  },
  slidesPerView: 1,
  slidesPerGroup: 1,
  spaceBetween: 28,
  speed: 600,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
  loop: true,
  freeMode: true,
  // autoHeight: true,
  breakpoints: {
    768: {
      slidesPerView: 2,
      slidesPerGroup: 2,
    },
    1024: {
      slidesPerView: 3,
      slidesPerGroup: 3,
    },
  },
});