import { hideElement, showElement } from '/src/common/js';

const body = document.querySelector('body');
const modal = document.querySelector('[data-modal-offers]');
const backdrop = modal.querySelector('.backdrop');
const modalBtnClose = modal.querySelector('[data-modal-offers-btn-close]');
const modalBtnOpen = document.querySelector('[data-modal-offers-btn-open]');

modalBtnOpen.addEventListener('click', openModal);

export function openModal() {
  showElement(modal);
  body.classList.add('modalIsOpen');

  backdrop.addEventListener('click', onBackdropClick);
  window.addEventListener('keydown', onEscKeyPress);
  modalBtnClose.addEventListener('click', closeModal);
}
export function closeModal() {
  hideElement(modal);
  body.classList.remove('modalIsOpen');

  backdrop.removeEventListener('click', onBackdropClick);
  window.removeEventListener('keydown', onEscKeyPress);
  modalBtnClose.removeEventListener('click', closeModal);
}
function onBackdropClick(e) {
  if (e.currentTarget === e.target) closeModal();
}
function onEscKeyPress(e) {
  if (backdrop.classList.contains('isHidden')) return;
  if (e.code === 'Escape') closeModal();
}
